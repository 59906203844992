import React from 'react'
import Typography from '../Typography'
import { useTranslation } from '../../utils/localization'

// Define SVG icons as components with the desired properties
const UserIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="h-12 w-12 text-link"
    style={{ fill: 'currentColor' }}
    viewBox="0 0 24 24"
  >
    <path d="M12,5.5A3.5,3.5 0 0,1 15.5,9A3.5,3.5 0 0,1 12,12.5A3.5,3.5 0 0,1 8.5,9A3.5,3.5 0 0,1 12,5.5M5,8C5.56,8 6.08,8.15 6.53,8.42C6.38,9.85 6.8,11.27 7.66,12.38C7.16,13.34 6.16,14 5,14A3,3 0 0,1 2,11A3,3 0 0,1 5,8M19,8A3,3 0 0,1 22,11A3,3 0 0,1 19,14C17.84,14 16.84,13.34 16.34,12.38C17.2,11.27 17.62,9.85 17.47,8.42C17.92,8.15 18.44,8 19,8M5.5,18.25C5.5,16.18 8.41,14.5 12,14.5C15.59,14.5 18.5,16.18 18.5,18.25V20H5.5V18.25M0,20V18.5C0,17.11 1.89,15.94 4.45,15.6C3.86,16.28 3.5,17.22 3.5,18.25V20H0M24,20H20.5V18.25C20.5,17.22 20.14,16.28 19.55,15.6C22.11,15.94 24,17.11 24,18.5V20Z" />
  </svg>
)

const SecureIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="h-12 w-12 text-link"
    style={{ fill: 'currentColor' }}
    viewBox="0 0 24 24"
  >
    <path d="M12,12H19C18.47,16.11 15.72,19.78 12,20.92V12H5V6.3L12,3.19M12,1L3,5V11C3,16.55 6.84,21.73 12,23C17.16,21.73 21,16.55 21,11V5L12,1Z" />
  </svg>
)

const LanguageIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="h-12 w-12 text-link"
    style={{ fill: 'currentColor' }}
    viewBox="0 0 24 24"
  >
    <path d="M12.87,15.07L10.33,12.56L10.36,12.53C12.1,10.59 13.34,8.36 14.07,6H17V4H10V2H8V4H1V6H12.17C11.5,7.92 10.44,9.75 9,11.35C8.07,10.32 7.3,9.19 6.69,8H4.69C5.42,9.63 6.42,11.17 7.67,12.56L2.58,17.58L4,19L9,14L12.11,17.11L12.87,15.07M18.5,10H16.5L12,22H14L15.12,19H19.87L21,22H23L18.5,10M15.88,17L17.5,12.67L19.12,17H15.88Z" />
  </svg>
)

const ToolsIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{ fill: 'currentColor' }}
    className="h-12 w-12 text-link"
    viewBox="0 0 24 24"
  >
    <path d="M14.5 11C14.78 11 15 11.22 15 11.5V13H9V11.5C9 11.22 9.22 11 9.5 11H14.5M20 13.55V10H18V13.06C18.69 13.14 19.36 13.31 20 13.55M21 9H3V3H21V9M19 5H5V7H19V5M8.85 19H6V10H4V21H9.78C9.54 20.61 9.32 20.19 9.14 19.75L8.85 19M17 18C16.44 18 16 18.44 16 19S16.44 20 17 20 18 19.56 18 19 17.56 18 17 18M23 19C22.06 21.34 19.73 23 17 23S11.94 21.34 11 19C11.94 16.66 14.27 15 17 15S22.06 16.66 23 19M19.5 19C19.5 17.62 18.38 16.5 17 16.5S14.5 17.62 14.5 19 15.62 21.5 17 21.5 19.5 20.38 19.5 19Z" />
  </svg>
)

const PromotionalRow = () => {
  const t = useTranslation()

  return (
    <div className="w-full flex flex-wrap md:flex-nowrap items-start justify-center bg-aldoo_black text-white py-8">
      <div className="flex flex-col w-[40%] sm:w-[30%] md:w-[15%] items-center justify-center sm:mx-4 mb-8 md:mb-0">
        <UserIcon />
        <Typography className="text-2xl md:text-3xl lg:text-4xl font-bold text-center mt-5">{t(`300,000+`)}</Typography>
        <Typography className="text-center mt-2">{t(`Users Served`)}</Typography>
      </div>
      <div className="flex flex-col w-[40%] sm:w-[30%] md:w-[15%] items-center justify-center sm:mx-4 mb-8 md:mb-0">
        <LanguageIcon />
        <Typography className="text-2xl md:text-3xl lg:text-4xl font-bold text-center mt-5">100+</Typography>
        <Typography className="text-center mt-2">{t(`Supported Languages`)}</Typography>
      </div>
      <div className="flex flex-col w-[40%] sm:w-[30%] md:w-[15%] items-center justify-center sm:mx-4">
        <SecureIcon />
        <Typography className="text-2xl md:text-3xl lg:text-4xl font-bold text-center mt-5">{t(`Secure`)}</Typography>
        <Typography className="text-center mt-2">{t(`Storage & Transfer`)}</Typography>
      </div>
      <div className="flex flex-col w-[40%] sm:w-[30%] md:w-[15%] items-center justify-center sm:mx-4">
        <ToolsIcon />
        <Typography className="text-2xl md:text-3xl lg:text-4xl font-bold text-center mt-5">{t(`Built-in`)}</Typography>
        <Typography className="text-center mt-2">{t(`Online Tools and Viewers`)}</Typography>
      </div>
    </div>
  )
}

export default PromotionalRow
