import React from 'react'
import Typography from '../Typography'

const AwardEaseOfUse = () => {

  return (
    <div className="h-[40px] flex flex-col items-start justify-between">
      <Typography className="font-medium text-text text-[16px] leading-none">
          Ease of Use
        </Typography>
      <img
        src="/static/images/award-ease-of-use.webp"
        className="h-[19px]"
      />
    </div>
  )
}

export default AwardEaseOfUse
