import React from 'react'
import Typography from '../Typography'

const AwardRunsAnywhere = () => {

  return (
    <div className="h-[40px] flex items-start justify-between gap-2">
      <img
        src="/static/images/award-runs-anywhere.webp"
        className="h-[40px]"
      />

      <div className="flex flex-col justify-between h-[40px] leading-none">
        <Typography className="font-medium text-text">
          Runs
        </Typography>

        <Typography className="font-bold text-text">
          Anywhere
        </Typography>
      </div>
    </div>
  )
}

export default AwardRunsAnywhere
