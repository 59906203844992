import React, { useState, useEffect } from 'react'
import Typography from '../../components/Typography'
import { useTranslation } from '../../utils/localization'
import Button from '../../components/Button'
import { Suspense } from 'react'
// Lazy load the components
const VideoWithOverlay = React.lazy(() => import('../VideoWithOverlay'))

const features = [
  {
    name: 'Translate your documents on the go',
    info:
      'Have your documents translated no matter where you are. Our mobile suite will bring the power of out translation engines to your fingertips.',
  },
  {
    name: 'Fast & Intuitive',
    info:
      'Our mobile suite is designed to be easy to use, yet powerful enough to handle even the most complex documents.',
  },
  {
    name: 'All in one Tools',
    info:
      'Use our mobile suite to translate, scan, turn to speech, merge, split and much more.',
  },
]

const MobileFeaturesSection = () => {
  //implement isClient
  const [isClient, setIsClient] = useState(false)
  useEffect(() => {
    setIsClient(true)
  }, [])

  const t = useTranslation()

  return (
    <div
      id="mobile-app"
      className="flex flex-col-reverse md:flex-row items-center justify-center bg-canvas gap-10 lg:gap-20 px-8 md:px-4 py-[60px] md:py-[100px] mx-auto"
    >
      <div className="flex items-center justify-center md:justify-end  w-full sm:max-w-[600px] md:w-[45%] md:h-[600px]">
        <div className="w-[250px] sm:w-[300px] md:w-[290px] lg:w-[300px]">
          {isClient && (
            <Suspense fallback={<div>Loading...</div>}>
              <VideoWithOverlay />
            </Suspense>
          )}
        </div>
      </div>
      <div 
        className="flex flex-col justify-between w-full sm:w-[560px] md:w-[55%] md:h-[600px] text-[26px] sm:text-[34px] md:text-[44px] lg:text-[54px] font-extrabold leading-tight"
      >
        <div className="md:max-w-[600px]">
          <h2 className="text-text">
            PDF Translate Pro
          </h2>
          <h2
            className="text-red-500 mb-6 md:mb-10 mb-14"
          >
            {t`Mobile Suite`}
          </h2>
          <ul className="space-y-4">
            {features.map((feature, index) => (
              <li key={index}>
                {/* h3 copies the styling of Typography variant="h2" */}
                <h3 className="font-title text-text uppercase font-bold text-[16px] sm:text-[18px] lg:text-[22px]">{feature.name}</h3>
                <Typography
                  variant="paragraph"
                  className="text-text_secondary mb-6 md:mb-0"
                >
                  {feature.info}
                </Typography>
              </li>
            ))}
          </ul>
          <div className="w-full flex flex-co sm:flex-row items-center justify-center md:justify-start gap-2 md:space-x-5 mt-[40px] md:ml-[-16px]">
            <div className="w-[250px] md:flex-1">
              <a
                href="https://apps.apple.com/us/app/pdf-translator-pro/id1609232374"
                target="_blank"
                aria-label="Download on the App Store"
              >
                <img src="/static/images/app_store.webp" alt="Apple Store" />
              </a>
            </div>
            <div className="px-5 py-5 w-[250px] md:flex-1">
              <a
                href="https://play.google.com/store/apps/details?id=com.aldoo.pdftranslatorpro&hl=en&gl=US"
                target="_blank"
                aria-label="Get it on Google Play"
              >
                <img src="/static/images/google_play.webp" alt="Google Play" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MobileFeaturesSection
