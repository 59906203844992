import React, { useState, useEffect } from 'react'
import { useTranslation } from '../../utils/localization'
import ArticleCard from '../ArticleCard'
import { usePromiseWithAuth } from '../../utils/usePromise'
import PTRestAPI from '../../utils/pt_rest_api'
import { Spinner } from '../Spinner'

const FeaturedArticle = () => {
  const t = useTranslation()
  const [article, setArticle] = useState()

  const {
    result: response,
    pending,
    execute: fetchArticles,
    reset,
  } = usePromiseWithAuth(
    (auth) =>
      PTRestAPI.ListArticles(auth, {
        page: 1,
        itemsPerPage: 1,
        match: {
          status: 'published',
        },
      }),
    true,
    false,
  )

  //get the article
  useEffect(() => {
    if (pending) return
    if (response) {
      if (response?.articles?.length > 0) setArticle(response.articles[0])
    } else {
      fetchArticles()
    }
  }, [response])

  return (
    <div className="flex flex-col md:flex-row md:w-full items-center justify-center bg-neutral_1 gap-6 md:gap-10 lg:gap-20 px-8 md:px-4 py-[60px] md:py-0 mx-auto">
      {/* Left Section */}
      <div className="flex flex-col w-full sm:max-w-[580px] md:w-[45%] md:h-[600px] items-start md:items-end justify-between space-y-8 md:py-[153px]">
        <div>
          <h2 className="text-text text-left md:text-right text-[26px] sm:text-[34px] md:text-[44px] lg:text-[54px] font-extrabold mb-1 md:mb-2">
            {t`Featured Article`}
          </h2>
          <p className="text-text text-left md:text-right text-sm sm:text-base md:text-lg">
            {t`Find out everything about the world of translation... and more.`}
          </p>
        </div>
      </div>

      {/* Right Section */}
      <div className="flex w-full sm:w-[600px] md:w-[55%] md:h-[600px] items-center justify-start">
        {article ? (
          <ArticleCard key={article.articleID} {...article} />
        ) : (
          <Spinner />
        )}
      </div>
    </div>
  )
}

export default FeaturedArticle
